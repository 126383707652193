import React from "react"
import SecurityGuardsHeroSectionImageHeroSectionStyle from "./SecurityGuardsHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const SecurityGuardsHeroSection = ({ securityGuardsHeroSectionImage }) => {
  return (
    <section
      className={SecurityGuardsHeroSectionImageHeroSectionStyle.hero_section}
    >
      <Img
        className={SecurityGuardsHeroSectionImageHeroSectionStyle.image}
        fluid={securityGuardsHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div
        className={SecurityGuardsHeroSectionImageHeroSectionStyle.title_wrapper}
      >
        <h1>Физическа охрана</h1>
      </div>
    </section>
  )
}

export default SecurityGuardsHeroSection
