import React from "react"
import SecurityGuardsHeroSection from "./securityGuardsHeroSection/SecurityGuardsHeroSection"
import SecurityGuardsInfoSection from "./securityGuardsInfoSection/SecurityGuardsInfoSection"
import SecurityGuardsAdvantagesSection from "./securityGuardsAdvantagesSection/SecurityGuardsAdvantagesSection"
import SecurityGuardsSliderSection from "./securityGuardsSliderSection/SecurityGuardsSliderSection"

const SecurityGuardsTemplate = ({
  securityGuardsHeroSectionImage,
  securityGuardsInfoSectionImage,
}) => {
  return (
    <>
      <SecurityGuardsHeroSection
        securityGuardsHeroSectionImage={securityGuardsHeroSectionImage}
      />
      <SecurityGuardsInfoSection
        securityGuardsInfoSectionImage={securityGuardsInfoSectionImage}
      />
      <SecurityGuardsAdvantagesSection />
      <SecurityGuardsSliderSection />
    </>
  )
}

export default SecurityGuardsTemplate
