import React from "react"
import SecurityGuardsAdvantagesSectionStyle from "./SecurityGuardsAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const SecurityGuardsAdvantagesSection = props => {
  return (
    <section
      className={SecurityGuardsAdvantagesSectionStyle.advantages_section}
    >
      <div className={SecurityGuardsAdvantagesSectionStyle.advantages_content}>
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12">
            <Advantage number={1} advantage={"Подбор на охранители"} />{" "}
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <Advantage number={2} advantage={"Професионално оборудван екип"} />{" "}
          </div>{" "}
          <div className="col-lg-4 col-md-4 col-12">
            <Advantage
              number={3}
              advantage={"Завишен контрол на изпълнение на задълженията"}
            />{" "}
          </div>{" "}
        </div>
      </div>
    </section>
  )
}

export default SecurityGuardsAdvantagesSection
