import React from "react"
import SecurityGuardsInfoSectionStyle from "./SecurityGuardsInfoSectionStyle.module.styl"
import Img from "gatsby-image"

const SecurityGuardsInfoSection = ({ securityGuardsInfoSectionImage }) => {
  return (
    <section className={SecurityGuardsInfoSectionStyle.info_section}>
      <div className={SecurityGuardsInfoSectionStyle.intro_section_content}>
        <Img
          className={SecurityGuardsInfoSectionStyle.intro_img}
          fluid={securityGuardsInfoSectionImage[0].node.childImageSharp.fluid}
        />
        <div className={SecurityGuardsInfoSectionStyle.intro_text}>
          <span> „Спешъл Сикюрити Груп“ ООД </span> предлага физическа охранана
          своите клиенти,които са: търговски центрове; държавни и общински
          учреждения; складови и производствени бази; земеделски и животновъдни
          обекти. Фирмата инвестира в обучения, екипировка и оборудване, за да
          могат служителите да са на най-високо ниво и да реагират във всеки
          един момент. Мениджър контрол е в постоянен контакт с охранителите,
          като осъществява завишен контрол на изпълнение на задълженията.
        </div>
      </div>
    </section>
  )
}

export default SecurityGuardsInfoSection
